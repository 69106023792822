import React, { useRef, useCallback } from 'react'
import { ChevronDown } from 'react-feather'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import PolygonLogo from '../../assets/images/polygon-logo.png'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import { MenuItem } from '../../theme'
import { RefreshCw} from 'react-feather'


const FlyoutHeader = styled.div`
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
`
const FlyoutMenu = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundContainer};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px;
  position: absolute;
  top: 64px;
  width: 272px;
  z-index: 100;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
  @media screen and (min-width: 70px) {
    top: 50px;
  }
`
const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`
const NetworkLabel = styled.a`
  flex: 1 1 auto;
`
const SelectorLabel = styled(NetworkLabel)`
  display: none;
  @media screen and (min-width: 70px) {
    display: block;
    margin-right: 8px;
  }
`
const SelectorControls = styled.div<{ interactive: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundContainer};
  border-radius: 12px;
  color: ${({ theme }) => theme.text1};
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
`
const SelectorLogo = styled(Logo)<{ interactive?: boolean }>`
  margin-right: ${({ interactive }) => (interactive ? 8 : 0)}px;
  @media screen and (min-width: 70px) {
    margin-right: 8px;
  }
`
const SelectorWrapper = styled.div`
  @media screen and (min-width: 70px) {
    position: relative;
  }
`
const StyledChevronDown = styled(ChevronDown)`
  width: 12px;
`

export default function NetworkSelector() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.NETWORK_SELECTOR)
  const toggle = useToggleModal(ApplicationModal.NETWORK_SELECTOR)
  useOnClickOutside(node, open ? toggle : undefined)

  const showSelector = Boolean(true)

  const conditionalToggle = useCallback(() => {
    if (showSelector) {
      toggle()
    }
  }, [showSelector, toggle])

  
  return (
    <SelectorWrapper>
      <SelectorControls onClick={conditionalToggle} interactive={showSelector}>
        <RefreshCw className='mr10'/>
        <SelectorLogo interactive={showSelector} src={EthereumLogo} />
        <SelectorLabel href="https://polygon.materia.exchange">Ethereum</SelectorLabel>
        {/* {showSelector && <StyledChevronDown />} */}
      </SelectorControls>
      {/* {open && (
        <FlyoutMenu>
          <FlyoutHeader>
            <Trans>Select a network</Trans>
          </FlyoutHeader>
          <MenuItem id="link" href="https://materia.exchange">
            <SelectorLogo src={EthereumLogo} />
            Ethereum
          </MenuItem>
        </FlyoutMenu>
      )} */}
    </SelectorWrapper>
  )
}